import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BreadCrumb } from "../../component/breadcrumb";
import { ContextProvider } from "../../context/BaseContext";
import ModalDiscuss from "./modalDiscuss";
import { removeSecTime, renderStatusPrivat } from "../../util/Functions";
import { LoadingBall } from "../../component/Loading";

export default function DiscussPrivate() {
  const context = useContext(ContextProvider);
  const { id } = useParams();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const [listDiscuss, setListDiscuss] = useState([]);
  const [detailClass, setDetailClass] = useState("");

  async function getDiscuss() {
    // console.log(id);
    try {
      let response = await context.getRequest(
        `getDiscussPrivateRequestMentor/${id}`
      );
      console.log("RESPONSE : ", response);
      if (response) {
        setListDiscuss(response.data.data.discuss);
        setDetailClass(response.data.data);
        // console.log(response.data.data);
      }
    } catch (error) {
      console.log("ERROR : ", error);
    }
  }

  async function changeStatusKelas(status) {
    setLoading(true);
    let form = {
      id: id,
      status,
    };

    try {
      let response = await context.postRequest(
        `mentor-request-private-change-status`,
        form
      );
      console.log("RESPONSE : ", response);
      if (response) {
        context.setNotification(
          "success",
          `Pengajuan kelas berhasil ${status == 1 ? "disetujui" : "dibatalkan"}`
        );
        getDiscuss();
      }
      setLoading(false);
    } catch (error) {
      console.log("ERROR : ", error);
      context.setNotification(
        "danger",
        "Terjadi kesalahan, coba beberapa saat lagi"
      );
      setLoading(false);
    }
  }

  function hendleDefaultModalData(val) {
    if (val) {
      console.log(val);
      setValue("peserta", val.qty_user);
      setValue("date_start", val.date_start);
      setValue("date_end", val.date_end);
      setValue("time_start", removeSecTime(val.time_start));
      setValue("time_end", removeSecTime(val.time_end));
      setValue("type", val.type);
      setValue("alamat", val.alamat_lengkap);
      setValue("price", val.price);
      setValue("no_wa", val.no_wa);
      setValue("message", val.message);
    }
  }

  useEffect(() => {
    getDiscuss();
    // console.log("SSS", state);
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Diskusi Private Kelas</h5>
              </div>
              <BreadCrumb
                pageName={"Diskusi Private Kelas"}
                links={[{ url: "", name: "Riwayat Privat Kelas" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title p-2">Detail Pengajuan Kelas</h5>
              {detailClass ? (
                <div>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Dari
                    </td>
                    <td>:</td>
                    <td className="p-2">{detailClass?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Kelas
                    </td>
                    <td>:</td>
                    <td className="p-2">{detailClass?.title}</td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "20px" }} className="p-2">
                      Metode
                    </td>
                    <td>:</td>
                    <td className="p-2">
                      {detailClass?.type == 1 ? "Online" : "Offline"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Tanggal
                    </td>
                    <td>:</td>
                    <td className="p-2">
                      <p className="m-0">
                        {moment(detailClass?.date_start).format("DD MMMM YYYY")}{" "}
                        s/d
                      </p>
                      <p className="m-0">
                        {moment(detailClass?.date_end).format("DD MMMM YYYY")}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Waktu
                    </td>
                    <td>:</td>
                    <td className="p-2">
                      {removeSecTime(detailClass?.time_start)} -{" "}
                      {removeSecTime(detailClass?.time_end)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Kuantitas
                    </td>
                    <td>:</td>
                    <td className="p-2">{detailClass?.qty_user} Orang</td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Alamat
                    </td>
                    <td>:</td>
                    <td className="p-2">{detailClass?.alamat_lengkap}</td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Imformasi Tambahan
                    </td>
                    <td>:</td>
                    <td className="p-2">{detailClass?.message}</td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Harga
                    </td>
                    <td>:</td>
                    <td className="p-2">
                      Rp {detailClass?.price ? detailClass.price : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ maxWidth: "100px" }} className="p-2">
                      Status
                    </td>
                    <td>:</td>
                    <td className="p-2">
                      {detailClass?.status == 1 ? (
                        <span
                          style={{
                            color: "#0f5132",
                            backgroundColor: "#d1e7dd",
                          }}
                          className="badge badge-pill badge-light"
                        >
                          Sepakat
                        </span>
                      ) : (
                        renderStatusPrivat(detailClass?.status)
                      )}
                    </td>
                  </tr>

                  <hr />
                  {detailClass.status == 3 && listDiscuss[0]?.price ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary col-12 mb-2"
                        disabled={loading}
                        onClick={() => changeStatusKelas(42)}
                      >
                        Batalkan
                      </button>
                      <button
                        type="button"
                        className="btn btn-success col-12 mb-2"
                        disabled={loading}
                        onClick={() => changeStatusKelas(1)}
                      >
                        Setuju
                      </button>
                    </>
                  ) : null}
                </div>
              ) : (
                <LoadingBall />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="card">
            <div className="card-body pt-4">
              {detailClass ? (
                <>
                  <div className="text-right">
                    <button
                      disabled={detailClass?.status != 3 || loading}
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modaldiscuss"
                      onClick={() => hendleDefaultModalData(listDiscuss[0])}
                    >
                      Ajukan penawaran
                    </button>
                  </div>
                  <hr />
                  <div style={{ height: "100vh", overflowX: "scroll" }}>
                    {listDiscuss.length == 0 ? (
                      <div className="text-center">
                        <p>Diskusi masih kosong</p>
                      </div>
                    ) : (
                      listDiscuss.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <div className="p-2 mt-3 mb-3">
                              <p
                                className="mb-1"
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "14px",
                                }}
                              >
                                {moment(item.created_at).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )}
                              </p>
                              <h5 className="mb-4">Dari {item.from_role}</h5>
                              <div
                                className="p-1"
                                style={{
                                  backgroundColor: "floralwhite",
                                  fontSize: "12px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="p-1">
                                  <RenderStatusPenawaran stat={item.status} />
                                </div>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Jumlah Peserta
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">{item.qty_user} Orang</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Tanggal
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {moment(item.date_start).format(
                                      "DD MMM YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(item.date_end).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Waktu
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {removeSecTime(item.time_start)} -{" "}
                                    {removeSecTime(item.time_end)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Metode
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {item.type == 1 ? "Online" : "Offline"}
                                  </td>
                                </tr>
                                {item.type == 2 && (
                                  <tr>
                                    <td
                                      style={{ maxWidth: "150px" }}
                                      className="p-1"
                                    >
                                      Alamat
                                    </td>
                                    <td>:</td>
                                    <td className="p-1">
                                      {item.alamat_lengkap}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Harga
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    Rp {item.price ? item.price : "-"}
                                  </td>
                                </tr>
                              </div>
                              <p style={{ fontSize: "14px" }} className="mt-4">
                                {item.discuss ? `"${item.discuss}"` : null}
                              </p>
                            </div>
                            <hr />
                          </div>
                        );
                      })
                    )}
                  </div>
                </>
              ) : (
                <LoadingBall />
              )}
            </div>
          </div>
        </div>
        <ModalDiscuss
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          watch={watch}
          context={context}
          getDiscuss={getDiscuss}
          reset={reset}
        />
      </div>
    </>
  );

  function RenderStatusPenawaran({ stat }) {
    let status = "Penawaran Baru";
    let bgColor = "#d1e7dd";
    let fColor = "#0f5132";

    switch (stat) {
      case 45:
        bgColor = "#f8d7da";
        fColor = "#842029";
        status = "Penawaran Anda ditolak Admin";
        break;

      case 42:
        bgColor = "#f8d7da";
        fColor = "#842029";
        status = "Anda membatalkan penawaran";
        break;

      case 40:
        bgColor = "#f8d7da";
        fColor = "#842029";
        status = "Siswa membatalkan penawaran";
        break;

      default:
        break;
    }

    return (
      <span
        style={{
          fontSize: "11px",
          color: fColor,
          backgroundColor: bgColor,
        }}
        className="badge badge-pill badge-light"
      >
        {status}
      </span>
    );
  }
}
