import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// import { useHistory, useParams } from "react-router-dom";
// import swal from "sweetalert";
// import { useForm } from "react-hook-form";
import DataTable from "../../../../component/DataTable";
import { LoadingBall } from "../../../../component/Loading";
// import { currencyFormat } from "../../../../util/Functions";

export default function ListPrivateKelas({
  path,
  is_private,
  context,
  getDataKelas,
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const [online, setOnline] = useState(false);
  const [offline, setOffline] = useState(false);

  async function hendleChangeStatus(event) {
    let form = new FormData();
    setLoading(true);
    switch (event.target.checked) {
      case true:
        form.append("is_private", true);
        let res = await context.postRequest(`MentorUpdateKelas/${id}`, form);
        if (res) {
          getDataKelas();
          setLoading(false);
        }
        break;
      case false:
        form.append("is_private", false);
        let ress = await context.postRequest(`MentorUpdateKelas/${id}`, form);
        if (ress) {
          getDataKelas();
          setLoading(false);
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>Pengaturan Kelas Privat</h5>
      </div>
      <div className="card-body">
        <div className="form-group mb-0 p-2">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={(event) => hendleChangeStatus(event)}
              type="checkbox"
              id="switch-private"
              checked={is_private}
            />
            <label htmlFor="switch-private" className="cr" />
          </div>
          {loading ? (
            <div
              style={{ width: "22px", height: "22px" }}
              className="spinner-border text-danger"
            />
          ) : (
            <label style={is_private ? { color: "green" } : { color: "red" }}>
              {is_private ? "Kelas Privat Aktif" : "Kelas Privat Tidak Aktif"}
            </label>
          )}
        </div>
        <div className="form-group mb-0 p-2">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={(event) => setOnline(event.target.checked)}
              type="checkbox"
              id="switch-online-private"
              checked={online}
            />
            <label htmlFor="switch-online-private" className="cr" />
          </div>
          <label style={online ? { color: "green" } : { color: "red" }}>
            {online ? "Metode Online Aktif" : "Metode Online Tidak Aktif"}
          </label>
        </div>
        <div className="form-group mb-0 p-2">
          <div className="switch switch-primary d-inline m-r-10">
            <input
              onChange={(event) => setOffline(event.target.checked)}
              type="checkbox"
              id="switch-offline-private"
              checked={offline}
            />
            <label htmlFor="switch-offline-private" className="cr" />
          </div>
          <label style={offline ? { color: "green" } : { color: "red" }}>
            {offline ? "Metode Offline Aktif" : "Metode offline Tidak Aktif"}
          </label>
        </div>
      </div>
    </div>
  );
}
