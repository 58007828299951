import React, { useState } from "react";
import { useParams } from "react-router-dom";
import MessageError from "../../component/MessageError";

export default function ModalDiscuss({
  register,
  handleSubmit,
  errors,
  watch,
  context,
  getDiscuss,
  reset,
}) {
  const { id } = useParams();
  const [loading, setloading] = useState(false);

  const onSubmit = async (data) => {
    setloading(true);
    // console.log("SUBMIT: ", data, id);
    let form = {
      qty_user: data.peserta,
      date_start: data.date_start,
      date_end: data.date_end,
      time_start: data.time_start,
      time_end: data.time_end,
      type: data.type,
      alamat_lengkap: data.alamat,
      price: data.price,
      discuss: data.discuss,
      no_wa: data.no_wa,
      message: data.message,
      // include_transport: data.include_transport,
    };

    // console.log("SEND: ", form);

    try {
      let response = await context.postRequest(
        `sendDiscussPrivateReqMentor/${id}`,
        form
      );
      console.log("RESPONSE : ", response);
      if (response) {
        setloading(false);
        document.getElementById("closeButton").click();
        context.setNotification("success", "Data diskusi berhasil dikirim");
        getDiscuss();
        // console.log(response.data.data);
        // setListDataPrivate(response.data.data);
      }
    } catch (error) {
      console.log("ERROR : ", error);
      setloading(false);
    }
  };

  return (
    <div
      className="modal fade"
      data-backdrop="static"
      data-keyboard="false"
      id="modaldiscuss"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <h5 className="modal-title">Penawaran Kelas</h5>
              <button
                id="closeButton"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => reset()}
                disabled={loading}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <label htmlFor="users" className="col-sm-3 col-form-label">
                  Jumlah Peserta
                </label>
                <div className="col-sm-8">
                  <input
                    {...register("peserta", {
                      required: "Jumlah peserta harus diisi",
                    })}
                    type="number"
                    className="form-control"
                    id="users"
                    placeholder="Masukkan jumlah peserta"
                  />
                  {errors.peserta ? (
                    <MessageError msg={errors.peserta.message} />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="date_start" className="col-sm-3 col-form-label">
                  Tanggal Mulai
                </label>
                <div className="col-sm-8">
                  <input
                    {...register("date_start", {
                      required: "Tanggal mulai harus diisi",
                    })}
                    type="date"
                    className="form-control"
                    id="date_start"
                    placeholder="Masukkan tanggal mulai"
                  />
                  {errors.date_start ? (
                    <MessageError msg={errors.date_start.message} />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="date_end" className="col-sm-3 col-form-label">
                  Tanggal Akhir
                </label>
                <div className="col-sm-8">
                  <input
                    {...register("date_end", {
                      required: "Tanggal akhir harus diisi",
                    })}
                    type="date"
                    className="form-control"
                    id="date_end"
                    placeholder="Masukkan tanggal akhir"
                  />
                  {errors.date_end ? (
                    <MessageError msg={errors.date_end.message} />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="time_start" className="col-sm-3 col-form-label">
                  Waktu Mulai
                </label>
                <div className="col-sm-8">
                  <input
                    {...register("time_start", {
                      required: "Waktu mulai harus diisi",
                    })}
                    type="time"
                    className="form-control"
                    id="time_start"
                    placeholder="Masukkan waktu mulai"
                  />
                  {errors.time_start ? (
                    <MessageError msg={errors.time_start.message} />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="time_end" className="col-sm-3 col-form-label">
                  Waktu Akhir
                </label>
                <div className="col-sm-8">
                  <input
                    {...register("time_end", {
                      required: "Waktu akhir harus diisi",
                    })}
                    type="time"
                    className="form-control"
                    id="time_end"
                    placeholder="Masukkan waktu akhir"
                  />
                  {errors.time_end ? (
                    <MessageError msg={errors.time_end.message} />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="type" className="col-sm-3 col-form-label">
                  Metode
                </label>
                <div className="col-sm-8">
                  <select
                    {...register("type", {
                      required: "Pilih salah satu metode belajar",
                    })}
                    className="form-control"
                    id="type"
                  >
                    <option value="">- Pilih Metode -</option>
                    <option value="1">Online</option>
                    <option value="2">Offline</option>
                  </select>
                  {errors.type ? (
                    <MessageError msg={errors.type.message} />
                  ) : null}
                </div>
              </div>
              {watch("type") == 2 && (
                <div className="form-group row">
                  <label htmlFor="alamat" className="col-sm-3 col-form-label">
                    Alamat
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("alamat", {
                        required: "Alamat harus diisi",
                      })}
                      rows={3}
                      className="form-control"
                      id="alamat"
                      placeholder="Masukkan alamat"
                    ></textarea>
                    {errors.alamat ? (
                      <MessageError msg={errors.alamat.message} />
                    ) : null}
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label htmlFor="price" className="col-sm-3 col-form-label">
                  Harga
                </label>
                <div className="col-sm-8">
                  <input
                    {...register("price", {
                      required: "Harga harus diisi",
                    })}
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Masukkan harga kelas"
                  />
                  {errors.price ? (
                    <MessageError msg={errors.price.message} />
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="include_transport"
                  className="col-sm-3 col-form-label"
                >
                  Transportasi
                </label>
                <div className="form-group col-sm-8 mb-0">
                  <div className="switch switch-primary d-inline m-r-10">
                    <input
                      // {...register("include_transport")}
                      type="checkbox"
                      id="include_transport"
                      onClick={(e)=>e.preventDefault()}
                    />
                    <label htmlFor="include_transport" className="cr" />
                  </div>
                  <label
                    style={
                      watch("include_transport")
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {watch("include_transport")
                      ? "Harga diatas sudah termasuk biaya transportasi"
                      : "Harga diatas belum termasuk biaya transportasi"}
                  </label>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="discuss" className="col-sm-3 col-form-label">
                  Catatan
                </label>
                <div className="col-sm-8">
                  <textarea
                    {...register("discuss", {
                      required: "Catatan harus diisi",
                    })}
                    rows={3}
                    className="form-control"
                    id="discuss"
                    placeholder="Masukkan catatan"
                  ></textarea>
                  {errors.discuss ? (
                    <MessageError msg={errors.discuss.message} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => reset()}
                disabled={loading}
              >
                Batal
              </button>
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary"
              >
                Ajukan Penawaran
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
