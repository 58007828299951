import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import swal from "sweetalert";
import { ContextProvider } from "../../../context/BaseContext";
import { RenderSections, ModalPreviewContents } from "./components-detailKelas";
import { setStorage, signatureKey } from "../../../util/Functions";
import { LoadingBall } from "../../../component/Loading";
import { BreadCrumb } from "../../../component/breadcrumb";
import ListLiveKelas from "./LiveKelas/listLiveKelas";
import ListPrivateKelas from "./PrivateKelas/listPrivateKelas";
import FilePendukungGlobal from "./FilePendukung/GlobalFile/index";

export default function RenderDetailKelas({ path }) {
  const history = useHistory();
  const { id } = useParams();
  const context = useContext(ContextProvider);

  const [loading, setLoading] = useState(false);

  const [sections, setSections] = useState([]);
  const [urlPreview, setUrlPreview] = useState("");
  const [titlePreview, setTitlePreview] = useState("");
  const [typePreview, setTypePreview] = useState("");
  const [listKuis, setListKuis] = useState([]);
  const [titleKelas, setTitleKelas] = useState("");
  const [coverImage, setCoverImage] = useState("");

  const [menuActive, setMenuActive] = useState("");

  const [is_live_class, setIs_live_class] = useState(false);
  const [is_self_paced, setIs_self_paced] = useState(false);
  const [is_private, setIs_private] = useState(false);

  async function getDataKelas() {
    setLoading(true);
    let response = await context.getRequest(`getKelasByID/${id}`);
    // console.log("DETAIL", response);
    if (response) {
      setLoading(false);
      // console.log(response.data.data);
      setSections(response.data.data.sections);
      setTitleKelas(response.data.data.title);
      setCoverImage(response.data.data.img);
      setIs_live_class(response.data.data.is_live_class?true:false);
      setIs_self_paced(response.data.data.is_self_paced?true:false);
      setIs_private(response.data.data.is_private?true:false);
      // if (response.data.data.is_live_class != null) {
      //   // console.log("RESPONSE", response.data.data.is_live_class);
      //   setIs_live_class(response.data.data.is_live_class);
      //   setIs_self_paced(response.data.data.is_self_paced);
      //   setIs_private(response.data.data.is_private);
      // }
    }
  }

  async function getListKuis() {
    let response = await context.getRequest(`getQuizByMentor`);
    if (response) {
      //console.log(response);
      if ("data" in response.data.data) {
        // console.log(response.data.data.data);
        setListKuis(response.data.data.data);
      }
    }
  }

  async function hendleChangeStatus(event) {
    let form = new FormData();
    switch (event.target.checked) {
      case true:
        form.append("is_self_paced", true);
        let res = await context.postRequest(`MentorUpdateKelas/${id}`, form);
        if (res) getDataKelas();
        break;
      case false:
        form.append("is_self_paced", false);
        let ress = await context.postRequest(`MentorUpdateKelas/${id}`, form);
        if (ress) getDataKelas();
        break;
      default:
        break;
    }
  }

  async function handleShowContent(title, url, type) {
    if (type != 2) {
      let response = await context.getRequestWithoutHost(url);
      if (response) {
        setTitlePreview(title);
        setUrlPreview(response.data.url);
        setTypePreview(type);
      }
    } else {
      setTitlePreview(title);
      setUrlPreview(url);
      setTypePreview(type);
    }
  }

  function handleCloseModal() {
    setTitlePreview("");
    setUrlPreview("");
    setTypePreview("");
  }

  async function getMenuActive() {
    let menu = await localStorage.menuActive;
    // console.log(">>>", menu);
    setMenuActive(menu);
  }

  useEffect(() => {
    getListKuis();
    getDataKelas();
    getMenuActive();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Detail Kelas : {titleKelas}</h5>
              </div>
              <BreadCrumb
                pageName={"Detail Kelas"}
                links={[{ url: "/kelas", name: "Kelas" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a
                className={
                  menuActive == "default" ? "nav-link active" : "nav-link"
                }
                id="pills-kelas-tab"
                data-toggle="pill"
                href="#kelas"
                role="tab"
                aria-controls="pills-kelas"
                aria-selected="true"
                onClick={async () => await setStorage("menuActive", "default")}
              >
                Kelas
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  menuActive == "live" ? "nav-link active" : "nav-link"
                }
                id="pills-live-tab"
                data-toggle="pill"
                href="#live"
                role="tab"
                aria-controls="pills-live"
                aria-selected="false"
                onClick={async () => await setStorage("menuActive", "live")}
              >
                Live Kelas <span className="badge badge-info">Beta</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  menuActive == "private" ? "nav-link active" : "nav-link"
                }
                id="pills-privat-tab"
                data-toggle="pill"
                href="#privat"
                role="tab"
                aria-controls="pills-privat"
                aria-selected="false"
                onClick={async () => await setStorage("menuActive", "private")}
              >
                Kelas Privat <span className="badge badge-info">Beta</span>
              </a>
            </li> 
            <li className="nav-item">
              <a
                className={
                  menuActive == "file" ? "nav-link active" : "nav-link"
                }
                id="pills-live-tab"
                data-toggle="pill"
                href="#file"
                role="tab"
                aria-controls="pills-live"
                aria-selected="false"
                onClick={async () => await setStorage("menuActive", "file")}
              >
                File Pendukung <span className="badge badge-info">Beta</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content" id="pills-tabContent">
        <div
          className={
            menuActive == "default"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="kelas"
          role="tabpanel"
          aria-labelledby="pills-kelas-tab"
        >
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              {/* <button
                onClick={() => {
                  history.goBack();
                }}
                className="btn btn-primary"
              >
                <i className="fas fa-arrow-left" /> Kembali
              </button> */}
              <div className="btn-group">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  Section | Materi | Kuis
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${path}/add-section`);
                    }}
                    className="dropdown-item"
                    href="#!"
                  >
                    <i className="fas fa-hashtag mr-1"></i>Tambah Section
                  </a>
                  <hr />
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `${path}/add-content-video`,
                        state: sections,
                      });
                    }}
                    className="dropdown-item"
                    href="#!"
                  >
                    <i className="fas fa-video mr-1"></i>Tambah Materi Video
                  </a>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `${path}/add-content-PDF`,
                        state: sections,
                      });
                    }}
                    className="dropdown-item"
                    href="#!"
                  >
                    <i className="fas fa-file-pdf mr-1"></i>Tambah Materi PDF
                  </a>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `${path}/add-content-kuis`,
                        state: sections,
                      });
                    }}
                    className="dropdown-item"
                    href="#!"
                  >
                    <i className="fas fa-question mr-1"></i>Tambah Kuis
                  </a>
                </div>
              </div>
              <div className="form-group mb-0">
                <div className="switch switch-primary d-inline m-r-10">
                  <input
                    onChange={(event) => hendleChangeStatus(event)}
                    type="checkbox"
                    id="switch-default"
                    checked={is_self_paced}
                  />
                  <label htmlFor="switch-default" className="cr" />
                </div>
                <label
                  style={is_self_paced ? { color: "green" } : { color: "red" }}
                >
                  {is_self_paced
                    ? "Kelas Mandiri Aktif"
                    : "Kelas Mandiri Tidak Aktif"}
                </label>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="user-profile-list">
                    <div className="dt-responsive table-responsive">
                      {loading ? (
                        <LoadingBall />
                      ) : (
                        <table id="user-list-table" className="table nowrap">
                          {sections.length != 0 ? (
                            <tbody>
                              {sections.map((section, idx) => (
                                <RenderSections
                                  key={idx}
                                  section={section}
                                  history={history}
                                  path={path}
                                  swal={swal}
                                  context={context}
                                  getDataKelas={getDataKelas}
                                  allSections={sections}
                                  handleShowContent={handleShowContent}
                                  listKuis={listKuis}
                                />
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  <div className="d-inline-block align-middle">
                                    <div className="d-inline-block">
                                      <h6 className="m-b-0">
                                        Kelas ini belum memiliki materi
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ModalPreviewContents
                title={titlePreview}
                url={urlPreview}
                type={typePreview}
                handleCloseModal={handleCloseModal}
              />
            </div>
          </div>
        </div>
        {/* LIVE KELAS */}
        <div
          className={
            menuActive == "live" ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="live"
          role="tabpanel"
          aria-labelledby="pills-live-tab"
        >
          <ListLiveKelas
            context={context}
            is_live_class={is_live_class}
            getDataKelas={getDataKelas}
            path={path}
            coverImage={coverImage}
            titleKelas={titleKelas}
          />
        </div>
        {/* END LIVE KELAS */}
        {/* PRIVATE KELAS */}
        <div
          className={
            menuActive == "private"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="privat"
          role="tabpanel"
          aria-labelledby="pills-privat-tab"
        >
          <ListPrivateKelas
            path={path}
            is_private={is_private}
            context={context}
            getDataKelas={getDataKelas}
          />
        </div>
        {/* END PRIVATE KELAS */}
        {/* FILE PENDUKUNG KELAS */}
        <div
          className={
            menuActive == "file" ? "tab-pane fade show active" : "tab-pane fade"
          }
          id="file"
          role="tabpanel"
          aria-labelledby="pills-file-tab"
        >
          <FilePendukungGlobal path={path} context={context} />
        </div>
        {/* END FILE PENDUKUNG KELAS */}
      </div>
    </>
  );
}
