import moment from "moment";
import React, { useState } from "react";
import {
  RenderStatusLiveKelas,
  removeSecTime,
} from "../../../../util/Functions";

export function RenderDetailLive({ item }) {
  return (
    <>
      <tr>
        <td className="p-2">Tanggal Mulai</td>
        <td>:</td>
        <td className="p-2">
          {moment(item.date_start).format("DD MMMM YYYY")}
        </td>
      </tr>
      <tr>
        <td className="p-2">Tanggal Akhir</td>
        <td>:</td>
        <td className="p-2">{moment(item.date_end).format("DD MMMM YYYY")}</td>
      </tr>
      <tr>
        <td className="p-2">Jam Mulai</td>
        <td>:</td>
        <td className="p-2">{removeSecTime(item.time_start)}</td>
      </tr>
      <tr>
        <td className="p-2">Jam Akhir</td>
        <td>:</td>
        <td className="p-2">{removeSecTime(item.time_end)}</td>
      </tr>
      <tr>
        <td className="p-2">Status</td>
        <td>:</td>
        <td className="p-2">
          <RenderStatusLiveKelas status={item.status} />
        </td>
      </tr>
    </>
  );
}

export function RenderJadwal({ val, context, hideZoomButton = null }) {
  var e = moment(val.start_time, "YYYY-MM-DD hh:mm:ss")
    .add(val.duration, "minutes")
    .unix();
  var s = moment(val.start_time, "YYYY-MM-DD hh:mm:ss")
    .subtract(30, "minutes")
    .unix();
  var n = moment().unix();
  var zoom = JSON.parse(val.data);

  async function refreshSignature(meetingID) {
    if (meetingID) {
      let response = await context.getRequest(
        "signature-mentor?zoom_id=" + meetingID
      );
      if (response) {
        // console.log("Signature", response.data);
        if (response.data.status) {
          window.open(
            process.env.PUBLIC_URL + `/zoom-fullscreen/${response.data.data}`,
            "_blank"
          );
        } else {
          console.log(response.data.message);
        }
      }
    } else {
      context.setNotification(
        "danger",
        "Kelas ini belum publish, mohon tunggu perubahan status dari admin"
      );
    }
  }

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }

  if (n >= s && n <= e) {
    // alert('berlangsung')
    return (
      <>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <div className="mb-1">
              <span className="badge badge-success mr-1">
                Sedang berlangsung
              </span>
            </div>
            <span style={{ fontStyle: "italic" }} className="mr-1">
              {moment(val.start_time).format("dddd, DD MMMM YYYY")}
            </span>
            <span
              style={{
                color: "rgb(150, 150, 150)",
                fontStyle: "italic",
              }}
            >
              ({moment(val.start_time).format("HH:mm")} -{" "}
              {moment(val.start_time).add(val.duration, "m").format("HH:mm")})
            </span>
            <p
              className="mb-1 mt-1"
              style={{ fontSize: "14px", fontWeight: "bolder" }}
            >
              {val.topic}
            </p>
            <p className="mb-1" style={{ color: "rgb(150, 150, 150)" }}>
              {val.agenda}
            </p>
          </div>
          <div className="p-1 d-flex align-items-center justify-content-center">
            {hideZoomButton == null && (
              <>
                <button
                  onClick={() => {
                    refreshSignature(val.zoom_id);
                  }}
                  className="btn btn-sm btn-primary mr-2"
                >
                  Join
                </button>
                <button
                  onClick={() => {
                    if (val.data != "") {
                      var json = JSON.parse(val.data);
                      window.open(json.start_url, "_blank");
                      // console.log(json)
                    }
                  }}
                  className="btn btn-sm btn-info"
                >
                  Join via zoom
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  } else if (n <= s) {
    return (
      <>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <div className="mb-1">
              <span className="badge badge-secondary mr-1">Belum dimulai</span>
            </div>
            <span style={{ fontStyle: "italic" }} className="mr-1">
              {moment(val.start_time).format("dddd, DD MMMM YYYY")}
            </span>
            <span
              style={{
                color: "rgb(150, 150, 150)",
                fontStyle: "italic",
              }}
            >
              ({moment(val.start_time).format("HH:mm")} -{" "}
              {moment(val.start_time).add(val.duration, "m").format("HH:mm")})
            </span>
            <p
              className="mb-1 mt-1"
              style={{
                fontSize: "14px",
                fontWeight: "bolder",
              }}
            >
              {val.topic}
            </p>
            <p className="mb-1" style={{ color: "rgb(150, 150, 150)" }}>
              {val.agenda}
            </p>
          </div>
        </div>
      </>
    );
  } else if (n >= e) {
    return (
      <>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <div className="mb-1">
              <span className="badge badge-danger mr-1">Berakhir</span>
            </div>
            <span style={{ fontStyle: "italic" }} className="mr-1">
              {moment(val.start_time).format("dddd, DD MMMM YYYY")}
            </span>
            <span
              style={{
                color: "rgb(150, 150, 150)",
                fontStyle: "italic",
              }}
            >
              ({moment(val.start_time).format("HH:mm")} -{" "}
              {moment(val.start_time).add(val.duration, "m").format("HH:mm")})
            </span>
            <p
              className="mb-1 mt-1"
              style={{
                fontSize: "14px",
                fontWeight: "bolder",
              }}
            >
              {val.topic}
            </p>
            <p className="mb-1" style={{ color: "rgb(150, 150, 150)" }}>
              {val.agenda}
            </p>
          </div>
          <div className="text-center d-flex align-items-center p-1">
            <div>
              <button
                disabled={!val.zoom_records}
                type="button"
                onClick={() => {
                  if (val.zoom_records)
                    window.open(val.zoom_records.linkDownload);
                }}
                className="btn btn-primary btn-sm mb-2"
              >
                Download
              </button>
              {val.zoom_records ? (
                <p style={{ margin: "0" }}>
                  {bytesToSize(val.zoom_records.size)}
                </p>
              ) : (
                <p
                  style={{
                    margin: "0",
                    color: "red",
                    maxWidth: "fit-content",
                    fontSize: "11px",
                  }}
                >
                  *video record belum tersedia
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function ModalLiveDraft({
  liveDataDraft,
  coverImage,
  titleKelas,
  context,
}) {
  const [errorCover, setErrorCover] = useState(false);
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Modal title
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title p-2">Detail Kelas</h5>
                    <div
                      className="text-center"
                      style={{ backgroundColor: "whitesmoke" }}
                    >
                      {coverImage && (
                        <img
                          src={
                            errorCover
                              ? process.env.PUBLIC_URL +
                                `/assets/images/noImage.png`
                              : coverImage
                          }
                          alt="cover-className"
                          className="img-fluid"
                          style={{ height: "150px" }}
                          onError={() => setErrorCover(true)}
                        />
                      )}
                    </div>
                    <tr>
                      <td className="p-2">Nama Kelas</td>
                      <td>:</td>
                      <td className="p-2">{titleKelas}</td>
                    </tr>
                    {liveDataDraft.length != 0 && (
                      <RenderDetailLive item={liveDataDraft} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title p-2">Jadwal Live</h5>
                    <div id="list-schedule">
                      {liveDataDraft.length != 0 && (
                        <>
                          {liveDataDraft.webinars_temp.length == 0 ? (
                            <div className="text-center">
                              <p style={{ fontStyle: "italic" }}>
                                Tidak memiliki jadwal
                              </p>
                            </div>
                          ) : (
                            <>
                              {liveDataDraft?.webinars_temp.map((item, idx) => (
                                <RenderJadwal
                                  key={idx}
                                  val={item}
                                  context={context}
                                  hideZoomButton={true}
                                />
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
