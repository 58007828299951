import React, { createContext, Component, useState } from 'react'
import { host } from '../util/host'
import {decryptString,getStorage,decodeJWT} from '../util/Functions'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

export const ContextProvider = createContext()
export default class App extends Component {
    state = {
        login: false,
        messageExpire:false,
        user:{},
        supportFirebase:true,
        profile:{},
        notification:{
            show:false,
            type:'default',
            message:''
        },
        menus:[],
        discussCountNotif:0
    }
    async componentDidMount(){
        this.checkStatusLogin()
        // this.setNotification('success','Welcome mentor')
        // console.log("STATUS LOGIN",l)
    }
    checkStatusLogin=async()=>{
        let l=localStorage.getItem('login')
        if(l){
            this.setState({login:true})
        }else{
            this.setState({login:false})
        }
    }
    setNotification=(type='success',message='no message')=>{
        if(type!=this.state.notification.type && message!=this.state.notification.message){
            this.setState({notification:{
                show:true,
                type,
                message
            }})
        }
    }
    getCountNotif=async()=>{
        let l=await getStorage('profile_mentor')
        if(l){
            let response=await this.getRequest('chat-notif-count-mentor',false);
            if(response){
                // console.log('COUNT NOTIF',response.data.data.unread)
                // this.setState({discussCountNotif:response.data.data.unread})
            }
        }
    }
    setValue = (key, value) => {
        this.setState({ [key]: value })
    }
    getToken=async()=>{
        // let us=await localStorage.getItem('username')
        // let pas=await localStorage.getItem('password')
        let w=await localStorage.getItem('with')
        // let username=await decryptString(us)
        // let password=await decryptString(pas)
        var username=''
        var password=''
        
        let auth=await localStorage.getItem('auth')
        if(auth){
            auth=await decryptString(auth)
            auth=auth.split('|')
            username=auth[0]
            password=auth[1]
        }
        
        if(username && password){
            let response=await this.postRequest('login_mentor',JSON.stringify({
                "email": username,
                "password": password,
                "remember_me": false,
                // 'oauth_provider':w
            }),'json',false);
            if(response){
                // var res=await decodeJWT(response)
                // console.log("REQUEST TOKEN : ",response,res)
                // let token=await encryptString(response.access_token)
                await localStorage.setItem('token',response.access_token)
                return true
            }
        }
        return false
    }
    requestToken=async()=>{
        let t = await localStorage.getItem('token')
        // let token=''
        // if(t){
        //     token=await decryptString(t)
        // }
        return t
    }
    getRequest = async (url) => {
        // this.checkStatusLogin()
        // let token = await localStorage.getItem('token')
        // let t = await localStorage.getItem('token')
        let token=await this.requestToken()
        console.log(token)
        let header = {}
        if (token) {
            header['Authorization'] = `Bearer ${token}`
        }
        header['Content-Type']='application/json';
        // console.log('header',header)
        try {
            const response = await axios({
                method: 'get',
                url: host+url,
                headers:header,
            });
            var res=await decodeJWT(response.data)
            // console.log('REPSONSE FROM : ',res)
            return res={data:res}
    
        } catch (error) {
            if(error.response){
                console.log(`ERROR RESPONSE GET ${url}: `,error.response)
                console.log(`ERROR TOKEN GET ${url}: `,token)
                console.log(`ERROR STACK GET ${url}: ${error.stack}`)
                let status=error.response.status;
                if(status==401){
                    if(this.state.login){
                        var re=await this.getToken();
                        if(re){
                            await this.getRequest(url)
                        }
                    }else{
                        this.setNotification('danger','Unautorized')
                        this.setState({login:false})
                    }
                }else{
                    
                    // console.log(error.response.data.message)
                    if(typeof error.response.data.message !='undefined'){
                        this.setNotification('danger',error.response.data.message)
                    }
                    
                    // showMessage({
                    //     message:"Gagal",
                    //     description:JSON.stringify(error.response.data.message),
                    //     type:'danger'
                    // })
                    // return null;
                }
            }else{
                this.setNotification('danger','Tidak terhubung ke server')
            }
            
            // let status=error.response.status;
            console.log(error.response)
            // if(status==401){
            //     if(this.state.login){
            //         var re=await this.getToken();
            //         if(re){
            //             await this.getRequest(url)
            //         }
            //     }else{
            //         this.setNotification('danger','Kamu belum login y? untuk melihat ini kamu disarankan harus login dulu')
            //     }
            // }else{
            //     // showMessage({
            //     //     message:"Gagal",
            //     //     description:JSON.stringify(error.response.data.message),
            //     //     type:'danger'
            //     // })
            //     // return null;
            // }
            throw error
        }

    }

    getRequestWithoutHost = async (url) => {
        this.checkStatusLogin()
        // let token = await localStorage.getItem('token')
        // let t = await localStorage.getItem('token')
        let token=await this.requestToken()
        let header = {}
        if (token) {
            header['Authorization'] = `Bearer ${token}`
        }
        header['Content-Type']='application/json';
        // console.log('header',header)
        try {
            const response = await axios({
                method: 'get',
                url: url,
                headers:header,
            });
            var res=await decodeJWT(response.data)
            // console.log('REPSONSE FROM : ',res)
            return res={data:res}
            // return response
    
        } catch (error) {
            if(error.response){
                console.log(`ERROR RESPONSE GET ${url}: `,error.response)
                console.log(`ERROR TOKEN GET ${url}: `,token)
                console.log(`ERROR STACK GET ${url}: ${error.stack}`)
                let status=error.response.status;
                if(status==401){
                    if(this.state.login){
                        var re=await this.getToken();
                        if(re){
                            await this.getRequest(url)
                        }
                    }else{
                        this.setNotification('danger','Kamu belum login y? untuk melihat ini kamu disarankan harus login dulu')
                    }
                }else{
                    // showMessage({
                    //     message:"Gagal",
                    //     description:JSON.stringify(error.response.data.message),
                    //     type:'danger'
                    // })
                    // return null;
                }
            }else{
                this.setNotification('danger','Tidak terhubung ke server')
            }
            
            // let status=error.response.status;
            console.log(error.response)
            // if(status==401){
            //     if(this.state.login){
            //         var re=await this.getToken();
            //         if(re){
            //             await this.getRequest(url)
            //         }
            //     }else{
            //         this.setNotification('danger','Kamu belum login y? untuk melihat ini kamu disarankan harus login dulu')
            //     }
            // }else{
            //     // showMessage({
            //     //     message:"Gagal",
            //     //     description:JSON.stringify(error.response.data.message),
            //     //     type:'danger'
            //     // })
            //     // return null;
            // }
            throw error
        }

    }

    postRequest = async (url, form, type="json",notif = false, title = "berhasil", message = "berhasil") => {
        // var token = await localStorage.getItem('token')
        // let t = await localStorage.getItem('token')
        // let token=await decryptString(t)
        let token=await this.requestToken()
        try {
            this.checkStatusLogin()
            
            let header = {}
            if (token) {
                header['Authorization'] = `Bearer ${token}`;
            }
            header['Content-Type']='plain/text';
            if(type=='json'){
                header['Content-Type']='application/json'
            }else if(type='form'){
                header['Content-Type']='multipart/form-data'
            }
            //window.loadingUpload(1);
            const response = await axios({
                method: 'POST',
                url: host + url,
                headers: header,
                data: form,
                onUploadProgress:(progressEvent)=>{
                    var progress=parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
                    // console.log("POST PROGRESS",progress);
                    let splitUrl = url.split("/")
                    if(url == "addContent" || splitUrl[1] == "updateContent"){
                        // window.loadingUpload(progress);
                        swal({
                            title: `Uploading data ${progress}%`,
                            icon: process.env.PUBLIC_URL + `/assets/images/Ellipsis.svg`,
                            text: 'Mohon tunggu sebentar sedang meupload data',
                            buttons: false,
                            closeOnClickOutside:false
                        })
                        if (progress === 100) {
                            swal.close();
                            swal(
                                'Berhasil',
                                'Data berhasil disimpan',
                                'success'
                            )
                        }
                    }
                    // window.loadingUpload(progress);
                    // console.log(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )))
                }
            })
            if (notif) {
                window.notification(title, message, type)
            }

            var res=await decodeJWT(response.data)
            // console.log("decode JWT",res)
            return res
            // return response.data

        } catch (error) {
            let splitUrl = url.split("/")
            if(error.response){
                console.log(`ERROR RESPONSE GET ${url}: `,error.response)
                console.log(`ERROR TOKEN GET ${url}: `,token)
                console.log(`ERROR STACK GET ${url}: ${error.stack}`)
                let status=error.response.status;
                if(status==401){
                    if(this.state.login){
                        var re=await this.getToken();
                        if(re){
                            await this.postRequest(url,form,type)
                        }
                    }else{
                        this.setNotification('danger','Unautorized')
                        this.setState({login:false})
                    }
                }else{
                    if(url == "addContent" || splitUrl[1] == "updateContent"){
                        swal.close();
                        swal(
                            'Gagal',
                            'Upload gagal, coba beberapa saat lagi',
                            'error'
                        )
                    }
                    // console.log(error.response.data.message)
                    if(typeof error.response.data.message !='undefined'){
                        this.setNotification('danger',error.response.data.message)
                    }
                    
                    // showMessage({
                    //     message:"Gagal",
                    //     description:JSON.stringify(error.response.data.message),
                    //     type:'danger'
                    // })
                    return null;
                }
            }else{
                if(url == "addContent" || splitUrl[1] == "updateContent"){
                    swal.close();
                    swal(
                        'Gagal',
                        'Upload gagal, coba beberapa saat lagi',
                        'error'
                    )
                }
                this.setNotification('danger','Tidak terhubung ke server')
            }
            // console.log("RESPONSE POST "+path,form,"RESPONSE : "+JSON.stringify(error.response))
            // this.setState({loading:false})
            throw error
            
        }

    }
    putRequest = async (url, form, notif = false, title = "berhasil", message = "berhasil", type = 'success') => {
        try {
            // let token = await localStorage.getItem('token')
            let t = await localStorage.getItem('token')
            let token=await decryptString(t)
            let header = {}
            if (token) {
                header.token = token;
            }
            const response = await axios({
                method: 'PUT',
                url: host + url,
                headers: header,
                data: form,
                onUploadProgress:(progressEvent)=>{
                    var progress=parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
                    // window.loadingUpload(progress);
                    // console.log(parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )))
                }
            })
            if (notif) {
                window.notification(title, message, type)
            }
            var res=await decodeJWT(response.data)
            return res
            // return response.data

        } catch (error) {
            if(error.response){
                var status=error.response.status
                if(status==444){
                    localStorage.removeItem('expired')
                    this.setState({login:false})
                }else{
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log("Data", error.response.data);
                        console.log("STATUS", error.response.status);
                        console.log("HEADER", error.response.headers);
                        // window.notification(error.response.status, "Terjadi kesalahan", 'danger')
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log("REQUEST", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                }

                console.log(error.config);
                if (error.response.data.message == "username sudah digunakan") {
                    return error.response.data
                }else if(error.response.data.message == "nim sudah digunakan"){
                    return error.response.data
                } else {
                    window.notification(error.response.status, "Terjadi kesalahan", 'error')
                    return null
                }
            }else{
                window.notification('Error','Koneksi terputus dengan server','error')
            }
            return null;

        }

    }
    deleteRequest = async (url, notif = false, title = "berhasil", message = "berhasil", type = 'success') => {
        try {
            let token = await localStorage.getItem('token')
            // let t = await localStorage.getItem('token')
            // let token=await decryptString(t)
            let header = {}
            if (token) {
                header.token = token;
            }
            const response = await axios({
                method: 'DELETE',
                url: host + url,
                headers: header
            })
            if (notif) {
                // this.setNotification('success','')
                // window.notification(title, message, type)
            }
            var res=await decodeJWT(response.data)
            return res
            // return response.data

        } catch (error) {
            if(error.response){
                var status=error.response.status
                if(status==444){
                    localStorage.removeItem('expired')
                    this.setState({login:false})
                }else{
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log("Data", error.response.data);
                        console.log("STATUS", error.response.status);
                        console.log("HEADER", error.response.headers);
                        this.setNotification('danger', "Terjadi kesalahan")
                        // window.notification(error.response.status, "Terjadi kesalahan", 'danger')
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log("REQUEST", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                }

                console.log(error.config);

            }else{
                this.setNotification('danger', "Koneksi terputus dengan server")
                // window.notification('Error','Koneksi terputus dengan server','error')
            }
            return null;

        }

    }
    downloadRequest = async (url) => {
        let token = await localStorage.getItem('token')
        // let t = await localStorage.getItem('token')
        // let token=await decryptString(t)
        let header = {}
        if (token) {
            header.token = token;
        }

        console.log(header)
        try {
            const response = await axios({
                method: 'GET',
                url: host + url,
                headers: header,
                responseType: 'blob',
            })
            return response.data
        } catch (error) {
            if(error.response){
                var status=error.response.status
                if(status==444){
                    localStorage.removeItem('expired')
                    this.setState({login:false})
                }else if(status == 404){
                    // console.log("errorr",error.response);
                    window.notification(error.response.status, "Data file tidak ditemukan.", 'danger')
                }else{
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log("Data", error.response.data);
                        console.log("STATUS", error.response.status);
                        console.log("HEADER", error.response.headers);
                        window.notification(error.response.status, "Terjadi kesalahan", 'danger')
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log("REQUEST", error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                }

                console.log(error.config);

            }else{
                window.notification('Error','Koneksi terputus dengan server','error')
            }
            return null;
        }

    }
    localStorageClear=async()=>{
        await localStorage.clear()
    }
    render() {
        return (
            <ContextProvider.Provider
                value={{
                    ...this.state,
                    getToken:this.getToken.bind(this),
                    getRequest: this.getRequest.bind(this),
                    getRequestWithoutHost: this.getRequestWithoutHost.bind(this),
                    postRequest: this.postRequest.bind(this),
                    putRequest: this.putRequest.bind(this),
                    deleteRequest: this.deleteRequest.bind(this),
                    setValue: this.setValue.bind(this),
                    localStorageClear:this.localStorageClear.bind(this),
                    downloadRequest:this.downloadRequest.bind(this),
                    setNotification:this.setNotification.bind(this),
                    getCountNotif:this.getCountNotif.bind(this)
                }}
            >
                {this.props.children}
            </ContextProvider.Provider>
        )
    }
}
