import React, { useRef, useContext, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import MessageError from "../../component/MessageError";
import { ContextProvider } from "../../context/BaseContext";
import MetaTags from 'react-meta-tags';

export default function App({ path }) {
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const [agree, setAgree] = useState(false);

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      var response = await context.postRequest(
        "register_mentor",
        JSON.stringify({
          name: data.nama_lengkap,
          email: data.email,
          password: data.password,
          phone:data.no_hp,
          provider: "",
        })
      );
      if (response) {
        console.log(response);
        context.setNotification(
          "success",
          "Hore! Kamu berhasil mendaftar sebagai mentor, kami telah kirim email verifikasi ke alamat email kamu"
        );
      }
    } catch (error) {
      context.setNotification("danger", error.message);
      // console.log("error",error.message)
    }
  };
  const password = watch("password");
  return (
    <>
      <MetaTags>
          <title>Daftar Mentor | Wakool Academy</title>
          <meta name="description" content="Daftar sebagai mentor dan nikmati pengalaman terbaik anda sebagai mentor" />
          <meta property="og:title" content="Daftar Mentor" />
          <meta property="og:description" content="Daftar sebagai mentor dan nikmati pengalaman terbaik anda sebagai mentor" />
      </MetaTags>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="mb-4 f-w-400">Daftar sebagai mentor</h5>
        <div className="form-group mb-3">
          <label className="floating-label" htmlFor="name">
            Nama Lengkap
          </label>
          <input
            id="name"
            type="text"
            className="form-control"
            {...register("nama_lengkap", {
              required: "Nama lengkap harus diisi",
            })}
          />
          {errors.nama_lengkap ? (
            <MessageError msg={errors.nama_lengkap.message} />
          ) : null}
        </div>
        <div className="form-group mb-3">
          <label className="floating-label" htmlFor="no_hp">
            No Telp/WA
          </label>
          <input
            id="no_hp"
            type="text"
            className="form-control"
            {...register("no_hp", {
              required: "No handphone/WA harus diisi",
              minLength: {
                value: 11,
                message: "pastikan anda memasukan no tlp/wa dengan benar"
              }
            })}
          />
          {errors.no_hp ? (
            <MessageError msg={errors.no_hp.message} />
          ) : null}
        </div>
        <div className="form-group mb-3">
          <label className="floating-label" htmlFor="Email">
            Email
          </label>
          <input
            type="text"
            {...register("email", { required: "Email harus diisi" })}
            className="form-control"
            id="Email"
            placeholder
          />
          {errors.username ? (
            <MessageError msg={errors.username.message} />
          ) : null}
        </div>
        <div className="form-group mb-4">
          <label className="floating-label" htmlFor="Password">
            Password
          </label>
          <input
            type="password"
            {...register("password", { required: "Password harus diisi" })}
            className="form-control"
            id="Password"
            placeholder
          />
          {errors.password ? (
            <MessageError msg={errors.password.message} />
          ) : null}
        </div>
        <div className="form-group mb-4">
          <label className="floating-label" htmlFor="Password2">
            Ulangi Password
          </label>
          <input
            type="password"
            {...register("ulangi_password", {
              required: "Password harus diisi",
              validate: (val) => val == password || "Password tidak sama",
            })}
            className="form-control"
            id="Password2"
            placeholder
          />
          {errors.ulangi_password ? (
            <MessageError msg={errors.ulangi_password.message} />
          ) : null}
        </div>
        <div className="custom-control custom-checkbox text-left mb-4 mt-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Saya Menyetujui{" "}
            <a
              style={{ color: "#D71C20", textDecoration: "underline" }}
              onClick={(e) => {
                e.preventDefault();
              }}
              href="#!"
              data-toggle="modal"
              data-target="#TermsandConditions"
            >
              Syarat dan Ketentuan
            </a>
          </label>
        </div>
        <button
          disabled={!agree}
          type="submit"
          className="btn btn-block btn-primary mb-4"
          style={!agree ? { cursor: "not-allowed" } : { cursor: "pointer" }}
        >
          Daftar
        </button>
        <div className="text-center">
          {/* <div className="saprator my-4"><span>OR</span></div> */}
          {/* <button className="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-facebook-f" /></button>
                    <button className="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-google-plus-g" /></button>
                    <button className="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-twitter" /></button> */}
          {/* <p className="mb-2 mt-4 text-muted">Forgot password? <a href="auth-reset-password-img-side.html" className="f-w-400">Reset</a></p> */}
          <p className="mb-0 text-muted">
            Kamu punya akun?{" "}
            <a href={`${path}`} className="f-w-400">
              Masuk
            </a>
          </p>
        </div>
      </form>
      <button
        style={{ display: "none" }}
        id="set-agree-TermsandConditions"
        onClick={() => setAgree(true)}
      ></button>
    </>
  );
}
