import React from 'react'
import Template from '../../component/Template'
import {Route, Switch,useHistory,useRouteMatch} from 'react-router-dom'

import Index from './index'
import DiscussPrivate from './discussPrivate'

export default function App(){
    const history = useHistory()
    let { path, url } = useRouteMatch();
    return(
        <Template>
            <Switch>
                <Route exact path={`${path}`}>
                    <Index path={path} />
                </Route>
                <Route exact path={`${path}/discuss/:id`}>
                    <DiscussPrivate />
                </Route>
            </Switch>
        </Template>
    )
}