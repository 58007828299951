import Template from "../../component/Template";
import React, { useState, useEffect, useContext } from "react";
import { ContextProvider } from "../../context/BaseContext";
import { useHistory, Route, Switch, useRouteMatch } from "react-router-dom";
import { getStorage } from "../../util/Functions";
import { getToken } from "../../config/firebase.config";

function App({}) {
  let { path, url } = useRouteMatch();
  const history = useHistory();
  const context = useContext(ContextProvider);
  const [detail, setDetail] = useState(null);
  const [statistik, setStatistik] = useState({
    homes: 0,
    search: 0,
    klikAll: 0,
    resume: [],
  });
  async function statusLogin() {
    // let t=await context.getToken()
    let u = await getStorage("auth");
    // console.log("TOKEN",u)
    if (u) {
      // console.log(u)
      context.setValue("login", true);
      let profile_mentor = await getStorage("profile_mentor");
      profile_mentor = JSON.parse(profile_mentor);
      //   console.log(profile_mentor)
      context.setNotification("success", `Haii ${profile_mentor.name}`);

      // // let token=await getStorage('token')
      // // console.log(token)
      getMentorHome();
      getStatistik();
    } else {
      history.replace("/auth");
    }
  }
  useEffect(() => {
    statusLogin();
  }, []);
  async function getMentorHome() {
    let response = await context.getRequest("MentorHome");
    if (response) {
      console.log("MENTOR HOME", response.data.data);
      setDetail(response.data.data);
    }
  }
  async function getStatistik() {
    let response = await context.getRequest("statistik");
    if (response) {
      console.log("MENTOR STATISTIK", response.data.data);
      setStatistik(response.data.data);
      //   setDetail(response.data.data)
    }
  }
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Dashboard</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">Dashboard</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Dashboard />
      <AjakanKelas />
      <hr />
      {/* <div className="card text-center">
            <div className="card-body">
                <h5 className="card-title">Statistik Kinerja</h5>
                
            </div>
        </div> */}
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center m-l-0">
                <div className="col-auto">
                  <i className="icon feather icon-home f-30 text-c-red"></i>
                </div>
                <div className="col-auto">
                  <h6 className="text-muted m-b-10">
                    Kelas kamu muncul dihome
                  </h6>
                  <h2 className="m-b-0">{statistik.homes}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center m-l-0">
                <div className="col-auto">
                  <i className="icon feather icon-search f-30 text-c-red"></i>
                </div>
                <div className="col-auto">
                  <h6 className="text-muted m-b-10">
                    Kelas kamu muncul dipencarian
                  </h6>
                  <h2 className="m-b-0">{statistik.search}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center m-l-0">
                <div className="col-auto">
                  <i className="icon feather icon-users f-30 text-c-red"></i>
                </div>
                <div className="col-auto">
                  <h6 className="text-muted m-b-10">
                    Siswa melihat kelas kamu
                  </h6>
                  <h2 className="m-b-0">{statistik.klikAll}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <div className="dt-responsive table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Kelas</th>
                      <th className="text-center">Dilihat siswa</th>
                      <th className="text-center">Tampil dipencarian</th>
                      <th className="text-center">Tampil dihome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statistik.resume.map((val, i) => {
                      var status = "";
                      if (val.public_status == 0) {
                        status = (
                          <span className="badge badge-danger">Draft</span>
                        );
                      } else if (val.public_status == 1) {
                        status = (
                          <span className="badge badge-warning">
                            Pengajuan terbit
                          </span>
                        );
                      } else if (val.public_status == 2) {
                        status = (
                          <span className="badge badge-info">Terbit</span>
                        );
                      }
                      return (
                        <tr key={i}>
                          <td>{++i}</td>
                          <td>
                            {val.title} {status}
                          </td>
                          <td className="text-center">{val.detail}</td>
                          <td className="text-center">{val.pencarian}</td>
                          <td className="text-center">{val.homes}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-sm-12" style={{minHeight:1500}}>
            <div className="card">
                <div className="card-header">
                    <h5>Dashboard</h5>
                </div>
                <div className="card-body">
                </div>
            </div>
        </div> */}

      {/* <Switch>
            <Route exact path={`${path}`}>
                <Dashboard/>
            </Route>
            <Route exact path={`${path}/kelas`}>
                <Kelas/>
            </Route>
        </Switch> */}
    </Template>
  );
  function AjakanKelas() {
    if (detail) {
      return (
        <div className="card text-left">
          <div className="card-body">
            <h5 className="card-title">
              {detail.jumlah_kelas == 0
                ? "Buat kelas mu sekarang!"
                : "Yuk buat lebih banyak kelas"}
            </h5>
            <p className="card-text">
              {detail.jumlah_kelas == 0
                ? "Ayo buat kelas baru, tingkatkan potensi pendapatan Anda"
                : "Ayo buat kelas baru, tingkatkan potensi pendapatan Anda"}{" "}
            </p>
            <a href={"/kelas"} className="btn  btn-primary">
              Buat kelas
            </a>
          </div>
        </div>
      );
    }
    return null;
  }
  function Dashboard() {
    if (detail) {
      return (
        <div className="row">
          <div className="col-md-6 col-xl-3">
            <div className="card bg-c-yellow order-card">
              <div className="card-body">
                <h6 className="text-white">Pendapatan</h6>
                <h2 className="text-white">Rp {detail.saldo}</h2>
                {/* <p className="m-b-0">$5,032 <i className="feather icon-arrow-up m-l-10"></i></p> */}
                <i className="card-icon feather icon-filter"></i>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="card bg-c-green order-card">
              <div className="card-body">
                <h6 className="text-white">Kelasku</h6>
                <h2 className="text-white">{detail.jumlah_kelas}</h2>
                {/* <p className="m-b-0">$5,032 <i className="feather icon-arrow-up m-l-10"></i></p> */}
                <i className="card-icon feather icon-filter"></i>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="card bg-c-blue order-card">
              <div className="card-body">
                <h6 className="text-white">Rating</h6>
                <h2 className="text-white">{detail.rating_mentor}</h2>
                {/* <p className="m-b-0">$5,032 <i className="feather icon-arrow-up m-l-10 m-r-10"></i>$1,055 <i className="feather icon-arrow-down"></i></p> */}
                <i className="card-icon feather icon-users"></i>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3">
            <div className="card bg-c-green order-card">
              <div className="card-body">
                <a href={"/buyer-transaction"}>
                <h6 className="text-white">Jumlah Siswa</h6>
                <h2 className="text-white">{detail.jumlah_siswa}</h2>
                </a>
                {/* <p className="m-b-0">$5,032 <i className="feather icon-arrow-down m-l-10 m-r-10"></i>$1,055 <i className="feather icon-arrow-up"></i></p> */}
                <i className="card-icon feather icon-radio"></i>
                
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default App;
