import React, { useContext, useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import MessageError from "../../component/MessageError";
import { ContextProvider } from "../../context/BaseContext";
import { setStorage, encryptString, decodeJWT } from "../../util/Functions";
import MetaTags from "react-meta-tags";

export default function App() {
  const history = useHistory();
  const context = useContext(ContextProvider);
  const [refresh, setRefresh] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    setRefresh(true);
    // console.log("DATA ",data)
    let form = JSON.stringify({
      email: data.username,
      password: data.password,
      remember_me: false,
    });
    let response = await context.postRequest("login_mentor", form);
    console.log("RESPONSE : ", response);
    if (response) {
      if (response.status == true) {
        let auth = await encryptString(data.username + "|" + data.password);
        await setStorage("auth", auth);
        await setStorage("token", response.access_token);
        await setStorage("role", "mentor");
        await setStorage("menuActive", "default");
        await getProfile();
        context.setValue("login", true);
        history.replace("/");
        reset();
      } else {
        context.setNotification("danger", response.access_token);
      }
    }
    setRefresh(false);
  };
  async function getProfile() {
    let response = await context.getRequest("profile_mentors2", false);
    let str = JSON.stringify(response.data.data);
    // str=encryptString(str)
    await setStorage("profile_mentor", str);
    console.log(JSON.parse(str));
  }
  useEffect(() => {
    async function a() {
      let a = await decodeJWT();
      console.log(a);
    }
    a();
  }, []);
  let { url } = useRouteMatch();
  return (
    <>
      <MetaTags>
        <title>Login Mentor | Wakool Academy</title>
        <meta
          name="description"
          content="Login sebagai mentor dan nikmati pengalaman terbaik anda sebagai mentor"
        />
        <meta property="og:title" content="Login Mentor" />
        <meta
          property="og:description"
          content="Login sebagai mentor dan nikmati pengalaman terbaik anda sebagai mentor"
        />
      </MetaTags>
      <h5 className="mb-4 f-w-400">Masuk sebagai mentor</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label className="floating-label" htmlFor="Email">
            Email
          </label>
          <input
            autoComplete={"off"}
            type="text"
            {...register("username", { required: "Email harus diisi" })}
            className="form-control"
            id="Email"
          />
          {errors.username ? (
            <MessageError msg={errors.username.message} />
          ) : null}
        </div>
        <div className="form-group mb-4">
          <label className="floating-label" htmlFor="Password">
            Password
          </label>
          <input
            autoComplete={"off"}
            type="password"
            {...register("password", { required: "Password harus diisi" })}
            className="form-control"
            id="Password"
          />
          {errors.password ? (
            <MessageError msg={errors.password.message} />
          ) : null}
        </div>
        <button
          type="submit"
          className="btn btn-block btn-primary mb-1"
          style={{ fontSize: 17 }}
        >
          Masuk{" "}
          {refresh ? (
            <div className="spinner-grow text-light" role="status"></div>
          ) : null}
        </button>
      </form>
      <div className="text-center">
        <p className="mb-2 mt-4 text-muted">
          Lupa password?{" "}
          <a href={`${url}/forgot-password`} className="f-w-400">
            Reset
          </a>
        </p>
        <p className="mb-0 text-muted">
          Tidak punya akun?{" "}
          <a href={`${url}/signup`} className="f-w-400">
            Daftar
          </a>
        </p>
      </div>
    </>
  );
}
