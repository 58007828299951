import React, { useContext, useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import MessageError from "../../component/MessageError";
import { ContextProvider } from "../../context/BaseContext";
import { setStorage, encryptString, decodeJWT } from "../../util/Functions";
export default function App() {
  const history = useHistory();
  const context = useContext(ContextProvider);
  const [refresh, setRefresh] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setRefresh(true);
    let form = new FormData();
    form.append("email", data.email);
    let response = await context.postRequest("mentor-forgotPassword", form);
    // console.log("RESPONSE >>> : ", response);
    if (response) {
    // console.log("RESPONSE : ", response);
      context.setNotification("success", response.message);
        
    }
    setRefresh(false);
  };
  async function getProfile() {
    let response = await context.getRequest("profile_mentors2", false);
    let str = JSON.stringify(response.data.data);
    // str=encryptString(str)
    await setStorage("profile_mentor", str);
    console.log(JSON.parse(str));
  }
  useEffect(() => {
    async function a() {
      let a = await decodeJWT();
      console.log(a);
    }
    a();
  }, []);
  let { url } = useRouteMatch();
  return (
    <>
      <h5 className="mb-4 f-w-400">Lupa password</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label className="floating-label" htmlFor="Email">
            Masukan Email
          </label>
          <input
            autocomplete="off"
            type="text"
            {...register("email", { required: "Email harus diisi" })}
            className="form-control"
            id="Email"
            placeholder
          />
          {errors.email ? <MessageError msg={errors.email.message} /> : null}
        </div>
        <button
          type="submit"
          className="btn btn-block btn-primary mb-1"
          style={{ fontSize: 17 }}
        >
          Kirim{" "}
          {refresh ? (
            <div className="spinner-grow text-light" role="status"></div>
          ) : null}
        </button>
      </form>
      <div className="text-center">
        <p className="mb-0 text-muted">
          Tidak punya akun?{" "}
          <a href={`/`} className="f-w-400">
            Login
          </a>
        </p>
      </div>
    </>
  );
}
