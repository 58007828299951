import Template from "../../component/Template";
import React, { useState, useEffect, useContext } from "react";
import { ContextProvider } from "../../context/BaseContext";
import { useHistory, Route, Switch, useRouteMatch } from "react-router-dom";
import { getStorage } from "../../util/Functions";
import { getToken } from "../../config/firebase.config";

function App({}) {
  const history = useHistory();
  const context = useContext(ContextProvider);
  const [data,setData]=useState([])
  async function getFaq(){
      let response = await context.getRequest('faq/mentor')
      if(response){
          console.log(response.data.data)
          setData(response.data.data)
      }
  }
  useEffect(() => {
    getFaq()
  }, []);
  
  return (
    <Template>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">FAQ</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Dashboard</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#!">FAQ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12">
        <h5 className="mb-3"></h5>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <ul className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  {data.map((item,index)=>{
                      return(
                        <li><a className={index==0?"nav-link text-left active":"nav-link text-left"} id={item.slug+"-tab"} data-toggle="pill" href={"#v-pills-"+item.slug} role="tab" aria-controls={"v-pills-"+item.slug} aria-selected="false">{item.name}</a></li>
                      )
                  })}
                </ul>
              </div>
              <div className="col-md-9 col-sm-12">
                <div className="tab-content" id="v-pills-tabContent">
                  {data.map((item,index)=>{
                      return(
                        <div className={index==0?"tab-pane fade show active":"tab-pane fade show"} id={"v-pills-"+item.slug} role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <p className="mb-0" dangerouslySetInnerHTML={{ __html:item.content}}/>
                            <ul>
                              {item.sub.map((item1,index1)=>{
                                return(
                                  <li><a className={"nav-link text-left"} data-toggle="pill" onClick={()=>{
                                    openTab("#v-pills-"+item1.slug)
                                  }} href={"javascript:void(0)"} role="tab" aria-controls={"v-pills-"+item1.slug} aria-selected="false">{item1.name}</a></li>
                                )
                              })}
                            </ul>
                        </div>
                      )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
  function openTab(tab){
    window.$('.nav-pills a[href="' + tab + '"]').tab('show');
  }
}

export default App;
