import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import MessageError from "../../../component/MessageError";
import { ContextProvider } from "../../../context/BaseContext";
import { convertToBase64 } from "../../../util/Functions";
import { BreadCrumb } from "../../../component/breadcrumb";

export default function AddContentVideo() {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const [sectionClass] = useState(location.state ? location.state : []);

  const [loading, setLoading] = useState(false);
  const [perviewVideo, setPerviewVideo] = useState("");
  const [saveVideo, setSaveVideo] = useState("");

  function handleAddVideo(file) {
    if (file.length != 0 && file[0].type == "video/mp4") {
      if (file[0].size < 314572800) {
        var fileURL = URL.createObjectURL(file[0]);
        let blob = file[0].slice(0, file[0].size, file[0].type);
        let newFile = new File(
          [blob],
          file[0].name.replace(
            /(\s+|[!@#$%^&*()]|(?:[.](?![a-z0-9]+$)))/gim,
            "_"
          )
        );
        console.log("???", file);
        //   let video64 = result.split(",");
        // console.log(newFile);
        if (fileURL && newFile) {
          // console.log({ fileURL, newFile });
          setPerviewVideo(fileURL);
          setSaveVideo(newFile);
        }
      } else {
        context.setNotification("danger", "Max upload video 300mb");
      }
    } else {
      context.setNotification(
        "danger",
        "Maaf, file yang anda upload bukan video"
      );
    }
  }

  const onSubmit = async (data) => {
    // console.log("Video data", saveVideo);
    // console.log(data);
    setLoading(true);
    let form = new FormData();
    form.append("title", data.judul);
    form.append("content_type_id", "1");
    form.append("contents_url", saveVideo);
    form.append("durasi", data.durasi);
    form.append("section_id", data.section);
    form.append("urutan", data.urutan);
    form.append("is_preview", data.isPreview);

    let response = await context.postRequest("addContent", form, "form");
    if (response) {
      setLoading(false);
      history.goBack();
      context.setNotification("success", "Materi berhasil di tambahkan");
      reset();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(location);
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Tambah Materi Video</h5>
              </div>
              <BreadCrumb
                pageName={"Tambah Materi Video"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-2">
              <div className="col-lg-5">
                <video
                  className="mb-3"
                  style={{ width: "100%" }}
                  src={perviewVideo}
                  type="video/mp4"
                  controls
                  autoPlay={false}
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                ></video>
                <p
                  style={{ color: "red", fontStyle: "italic" }}
                  className="mb-1"
                >
                  *Max upload video 300mb
                </p>
                <div className="custom-file">
                  <input
                    onChange={(event) => handleAddVideo(event.target.files)}
                    type="file"
                    className="custom-file-input"
                    id="file-choose"
                    accept=".mp4"
                    // required
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="file-choose"
                    style={{ borderRadius: "3px" }}
                  >
                    Pilih file video...
                  </label>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-group row">
                  <label htmlFor="judul" className="col-sm-2 col-form-label">
                    Judul
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("judul", { required: "Judul harus diisi" })}
                      type="text"
                      className="form-control"
                      id="judul"
                      placeholder="Masukkan judul materi"
                    />
                    {errors.judul && (
                      <MessageError msg={errors.judul.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Section</label>
                  <div className="col-sm-10">
                    <select
                      {...register("section", {
                        required: "Pilih salah satu section",
                      })}
                      className="form-control"
                    >
                      <option value="">- Pilih section -</option>
                      {sectionClass.length != 0 ? (
                        <>
                          {sectionClass.map((item, idx) => (
                            <option key={idx} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option disabled>Section tidak ditemukan</option>
                      )}
                    </select>
                    {errors.section && (
                      <MessageError msg={errors.section.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="durasi" className="col-sm-2 col-form-label">
                    Durasi (menit)
                  </label>
                  <div className="col-sm-10">
                    <input
                      onInput={(e) =>
                        (e.target.value =
                          !!e.target.value && Math.abs(e.target.value) >= 0
                            ? Math.abs(e.target.value)
                            : null)
                      }
                      {...register("durasi", {
                        required: "Durasi video harus diisi",
                      })}
                      type="text"
                      className="form-control"
                      id="durasi"
                      placeholder="Masukkan durasi materi"
                    />
                    {errors.durasi && (
                      <MessageError msg={errors.durasi.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="urutan" className="col-sm-2 col-form-label">
                    No Urutan
                  </label>
                  <div className="col-sm-10">
                    <input
                      onInput={(e) =>
                        (e.target.value =
                          !!e.target.value && Math.abs(e.target.value) >= 0
                            ? Math.abs(e.target.value)
                            : null)
                      }
                      {...register("urutan", {
                        required: "No urutan harus diisi",
                      })}
                      type="text"
                      className="form-control"
                      id="urutan"
                      placeholder="Masukkan no urutan materi"
                    />
                    {errors.urutan && (
                      <MessageError msg={errors.urutan.message} />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="is-preview"
                    className="col-sm-2 col-form-label"
                  >
                    Akses
                  </label>
                  <div className="col-sm-10">
                    <div className="form-group">
                      <div className="switch switch-primary d-inline m-r-10">
                        <input
                          {...register("isPreview")}
                          type="checkbox"
                          id="switch-p-1"
                        />
                        <label htmlFor="switch-p-1" className="cr" />
                      </div>
                      <label
                        style={
                          watch("isPreview")
                            ? { color: "#D71C20" }
                            : { color: "#373a3c" }
                        }
                      >
                        {watch("isPreview")
                          ? "Dapat diakses tanpa membeli kelas"
                          : "Dapat diakses setelah membeli kelas"}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row mt-5">
                  <label className="col-sm-2 col-form-label" />
                  <div className="col-sm-10">
                    <button
                      type="button"
                      onClick={() => {
                        history.goBack();
                      }}
                      className="btn btn-outline-secondary"
                    >
                      <i className="fas fa-arrow-left mr-1" />
                      Kembali
                    </button>
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary ml-2"
                    >
                      {loading ? (
                        <div
                          style={{ width: "20px", height: "20px" }}
                          className="spinner-border text-light mr-1"
                        />
                      ) : (
                        <i className="fas fa-save mr-1" />
                      )}
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
