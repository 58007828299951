import React from "react";
import jwt from "jsonwebtoken";
import { host } from "./host";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("!wrt@Academym3nt012!2)((0");

var apiKey = "Nj1u%tyud4#BestWakoolAcademy2021";
var webKey = "IniW3b2120#y4ngP4l1ngW%w";
var tokenKey = "12esP0n3se#J1112";

export const decodeJWT = async (response) => {
  try {
    // console.log("RESPONSE PARAMS : ",response)
    if (typeof response.type != "undefined") {
      var spl = response.data.split(".");
      var keyPair = response.keyPair;
      var data = spl[1].slice(0, -keyPair);
      var token = spl[0] + "." + data + "." + spl[2];
      var decoded = await jwt.verify(token, tokenKey);
      // console.log("decoded",decoded)
      return decoded.parse;
    }
    return response;
  } catch (error) {
    console.log("decodeJWT", error.stack);
    return error.stack;
  }
};
export const signatureKey = async () => {
  try {
    let response = await fetch(host + "signature-key");
    let json = await response.json();
    var decoded = jwt.verify(json.signatureKey, apiKey + json.keyPair);
    var salt = decoded.salt;
    var token = jwt.sign({ salt: decoded.salt }, webKey + json.keyPair);
    return {
      keyPair: json.keyPair,
      token,
      url: `&signatureKey=${token}&keyPair=${json.keyPair}`,
    };
  } catch (e) {
    return e.stack;
  }
};

export const encryptString = async (str) => {
  return await cryptr.encrypt(str);
};
export const decryptString = async (encrypt) => {
  // console.log("encrypt : ",encrypt)
  if (encrypt != null) {
    return await cryptr.decrypt(encrypt);
  }
  return null;
};

export const setStorage = async (key, value) => {
  try {
    //console.log('function setStorage ',key,value)
    await localStorage.setItem(key, value);
  } catch (e) {
    //console.log('cathc setStorage')
  }
};

export const getStorage = async (key, value) => {
  try {
    //console.log('function setStorage ',key,value)
    return await localStorage.getItem(key);
  } catch (e) {
    //console.log('cathc setStorage')
  }
};

export const currencyFormat = (num) => {
  if (num != null && num != "") {
    let n = parseFloat(num);
    return "" + n.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return 0;
  }
};

export function removeSecTime(time) {
  let newTime = time.split(":");
  return newTime[0] + ":" + newTime[1];
}

export const getRating = (total) => {
  function getValue(value) {
    switch (value) {
      case 0:
        return "lar la-star";
      case 50:
        return "las la-star-half-alt";
      case 100:
        return "las la-star";
    }
  }

  function getStars(value) {
    if (value > 0 && value < 1) {
      return [50, 0, 0, 0, 0];
    } else if (value == 1) {
      return [100, 0, 0, 0, 0];
    } else if (value > 1 && value < 2) {
      return [100, 50, 0, 0, 0];
    } else if (value == 2) {
      return [100, 100, 0, 0, 0];
    } else if (value > 2 && value < 3) {
      return [100, 100, 50, 0, 0];
    } else if (value == 3) {
      return [100, 100, 100, 0, 0];
    } else if (value > 3 && value < 4) {
      return [100, 100, 100, 50, 0];
    } else if (value == 4) {
      return [100, 100, 100, 100, 0];
    } else if (value > 4 && value < 5) {
      return [100, 100, 100, 100, 50];
    } else if (value >= 5) {
      return [100, 100, 100, 100, 100];
    } else {
      return [0, 0, 0, 0, 0];
    }
  }

  return (
    <>
      {getStars(total).map((value, idx) => {
        return (
          <li key={idx} className="list-inline-item">
            <i className={getValue(value)}></i>
          </li>
        );
      })}
    </>
  );
};

export const minuteToHour = (minutes = 0) => {
  if (minutes <= 60) {
    return minutes + " menit";
  } else {
    return Math.ceil(minutes / 60) + " jam";
  }
};

export const convertToBase64 = (file) => {
  return new Promise((resolve) => {
    // let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log("Called", reader);
      baseURL = reader.result;
      // console.log(baseURL);
      resolve(baseURL);
    };
    // console.log(fileInfo);
  });
};

//===========================FUNCTION CROP IMAGE==========================
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  return canvas.toDataURL("image/jpeg");

  // As a blob
  //   return new Promise(resolve => {
  //     canvas.toBlob(file => {
  //       resolve(URL.createObjectURL(file))
  //     }, 'image/jpeg')
  //   })
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const RenderStatusLiveKelas = ({ status }) => {
  let textStatus = "";
  let style = "";
  switch (status) {
    case 0:
      textStatus = "Draft";
      style = "badge bg-warning text-light";
      break;
    case 1:
      textStatus = "Publish";
      style = "badge bg-success text-dark";
      break;
    case 2:
      textStatus = "In Review";
      style = "badge bg-info text-light";
      break;
    case 4:
      textStatus = "Rejected";
      style = "badge bg-primary text-light";
      break;
    case 11:
      textStatus = "Berlangsung";
      style = "badge bg-success text-light";
      break;
    case 12:
      textStatus = "Selesai";
      style = "badge bg-dark text-light";
      break;
    case null:
      textStatus = "No changes";
      style = "badge bg-light text-dark";
      break;
    default:
      textStatus = "error";
      style = "badge bg-light text-danger";
      break;
  }
  return <span className={style}>{textStatus}</span>;
};

export function renderStatusPrivat(status) {
  let stat = "";
  let bgColor = "white";
  let fColor = "white";

  switch (status) {
    case 0:
      stat = "Siswa mengajukan penawaran";
      fColor = "#664d03";
      bgColor = "#fff3cd";
      break;
    case 1:
      stat = "Menunggu pembayaran siswa";
      fColor = "#084298";
      bgColor = "#cfe2ff";
      break;
    case 2:
      stat = "Menunggu persetujuan admin";
      fColor = "#055160";
      bgColor = "#cff4fc";
      break;
    case 3:
      stat = "Menunggu persetujuan Anda";
      fColor = "#084298";
      bgColor = "#cfe2ff";
      break;
    case 5:
      stat = "Menunggu persetujuan siswa";
      fColor = "#664d03";
      bgColor = "#fff3cd";
      break;
    case 40:
      stat = "Siswa membatalkan penawaran";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;
    case 42:
      stat = "Anda membatalkan tawaran";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;
    case 43:
      stat = "Penawaran Anda ditolak admin";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;
    case 45:
      status = "Penawaran siswa ditolak admin";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;

    default:
      break;
  }
  return (
    <span
      style={{ color: fColor, backgroundColor: bgColor }}
      className="badge badge-pill badge-light"
    >
      {stat}
    </span>
  );
}
