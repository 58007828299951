import React,{ useContext, useEffect, useState} from 'react'
import Template from "../../component/Template";
import { ContextProvider } from "../../context/BaseContext";
import DataTable from "../../component/DataTable";
import moment from "moment";
import "moment/locale/id";

export default function App(){
    const context = useContext(ContextProvider)
    const [data,setData]=useState([])
    const [search,setSearch]= useState('')
    const [limit,setLimit]=useState(10)
    const [offset,setOffset]=useState(0)
    const [totalRecord,setTotalRecord]=useState(0)
    async function getData() {
        setData([])
        let response = await context.getRequest(`mentor-transaction?search=${search}&limit=${limit}&offset=${offset}`)
        if(response){
            // console.log(response)
            setData(response.data.data)
            setTotalRecord(response.data.total)
        }
    }
    useEffect(() => {
        getData();
        
    },[search,limit,offset])
    return(
        <Template>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Transaksi Pembeli Kelas</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                <a href="/">
                                    <i className="feather icon-home" />
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card latest-update-card">
                <div className="card-header">
                    <h5>Transaksi Pembeli Kelas</h5>
                </div>
                <div className="card-body"><br/><br/>
                    <DataTable
                        className={"table table-striped table-bordered nowrap"}
                        data={data}
                        totalRows={totalRecord}
                        show={[10, 20, 30, 40, 50]}
                        onChangeSearch={(val) => {
                            console.log(val);
                            setSearch(val)
                        }}
                        onChangeLimit={(val) => {
                            console.log(val);
                            setLimit(val)
                        }}
                        onChangePage={(val) => {
                            console.log(val);
                            setOffset(val)
                        }}
                        column={["No","Tanggal", "Transaksi", "Pembeli"]}
                        renderItem={(item, i) => <RenderItem item={item} index={i} />}
                    />
                </div>
            </div>
            
        </Template>
    )
    function RenderItem({item,index}){
        return(
            <tr>
                <td>{offset+index+1}</td>
                <td>{item.transaction_time?moment(item.transaction_time).format("DD MMM YYYY"):'-'}</td>
                <td>{item.order_id}<br/>{item.title}<br/>Rp {item.gross_amount}</td>
                <td>{item.name}<br/>{item.email}</td>
            </tr>
        )
    }
}