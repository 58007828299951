import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import MessageError from "../../../../component/MessageError";
import { ContextProvider } from "../../../../context/BaseContext";
import { BreadCrumb } from "../../../../component/breadcrumb";

export default function AddFile() {
  const history = useHistory();
  const { contentId, id } = useParams();
  const context = useContext(ContextProvider);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    //   console.log(data, contentId);
    //   console.log(data.file[0]);
    if (data.file[0].size < 314572800) {
      setLoading(true);
      let form = new FormData();
      form.append("nama_file", data.namaFile);
      form.append("class_id", id);
      form.append("content_id", contentId);
      form.append("file", data.file[0]);

      let response = await context.postRequest("content-file-insert", form);
      if (response) {
        setLoading(false);
        history.goBack();
        context.setNotification("success", "File berhasil diupload");
        reset();
      } else {
        setLoading(false);
        context.setNotification("danger", "File gagal diupload");
      }
    } else {
      context.setNotification("danger", "Max upload file 300mb");
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Tambah File Pendukung</h5>
              </div>
              <BreadCrumb
                pageName={"Tambah File Pendukung"}
                links={[
                  { url: "/kelas", name: "Kelas" },
                  { url: "", name: "Detail Kelas" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <label htmlFor="namaFile" className="col-sm-2 col-form-label">
                Nama File
              </label>
              <div className="col-sm-6">
                <input
                  {...register("namaFile")}
                  type="text"
                  className="form-control"
                  id="namaFile"
                  placeholder="Masukkan nama File"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="file" className="col-sm-2 col-form-label">
                Upload file
              </label>
              <div className="col-sm-6">
                <input
                  {...register("file", { required: "file harus diisi" })}
                  type="file"
                  className="form-control"
                  id="file"
                />
                {errors.file && <MessageError msg={errors.file.message} />}
              </div>
            </div>
            <div className="form-group row mt-5">
              <label className="col-sm-2 col-form-label" />
              <div className="col-sm-10">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-outline-secondary"
                >
                  <i className="fas fa-arrow-left mr-1" />
                  Kembali
                </button>
                <button type="submit" className="btn btn-primary ml-2">
                  {loading ? (
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="spinner-border text-light mr-1"
                    />
                  ) : (
                    <i className="fas fa-save mr-1" />
                  )}
                  Simpan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
