import React,{useState,useContext,useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {ContextProvider} from './context/BaseContext'
import firebase,{getToken} from './config/firebase.config'

import Dashboard from './pages/Dashboard/index'
import SignIn from './pages/Authentication'
import Kelas from './pages/Kelas'
import Kuis from './pages/Kuis'
import Chat from './pages/Chat'
import Notifikasi from './pages/Notifikasi'
import Withdraw from './pages/Withdraw'
import ChangePassword from './pages/ChangePassword'
import Profile from './pages/Profile'
import Instruktur from './pages/Instruktur/router'
import WithdrawEdit from './pages/Withdraw/Edit'
import AdminLoginMentor from './pages/Authentication/AdminLoginMentor'
import ScheduleLiveKelas from './pages/ScheduleLiveKelas/index'
import FullscreenZoom from './component/FullscreenZoom'
import LaporanPenjualan from './pages/LaporanPenjualan/index'
import PrivateClass from './pages/PrivateClass/routes'
import NotFound from './pages/404/404'
import FAQ from './pages/faq/index'
import TransactionClass from './pages/transactions-class/index'
import { getStorage } from "./util/Functions";


export default function App(){
    const context=useContext(ContextProvider)
    useEffect(() => {
        context.getCountNotif()
        async function getTokenFirebase(){
            let token=await getToken()
            let user = await getStorage('profile_mentor')
            if(user){
                let form=new FormData();
                form.append('firebase_token_web', token);//console.log(form);
                let response = await context.postRequest('updateMentor', form);
                console.log("TOKEN FIREBASE:",response) 
            }
        }
        if(!firebase.messaging.isSupported()){
            // alert('not support')  
            context.setValue('supportFirebase',false)  
        }else{
            getTokenFirebase()
        }
    },[])
    return(
        <Router>
            <Switch>
                <Route path="/auth">
                    <SignIn />
                </Route>
                <Route path="/4dm1n/:token">
                    <AdminLoginMentor />
                </Route>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Route path="/kelas">
                    <Kelas />
                </Route>
                <Route path="/kuis">
                    <Kuis />
                </Route>
                <Route path="/chat">
                    <Chat />
                </Route>
                <Route path="/notifikasi">
                    <Notifikasi />
                </Route>
                <Route exact path="/withdraw">
                    <Withdraw />
                </Route>
                <Route path="/withdraw/rekening">
                    <WithdrawEdit />
                </Route>
                <Route path="/change-password">
                    <ChangePassword />
                </Route>
                <Route path="/profile">
                    <Profile />
                </Route>
                <Route path="/instruktur">
                    <Instruktur />
                </Route>
                <Route path="/live-schedule">
                    <ScheduleLiveKelas />
                </Route>
                <Route path="/zoom-fullscreen/:token">
                    <FullscreenZoom />
                </Route>
                <Route path="/laporan-penjualan">
                    <LaporanPenjualan />
                </Route>
                <Route path="/report-private-class">
                    <PrivateClass />
                </Route>
                <Route path="/faq">
                    <FAQ />
                </Route>
                <Route path="/buyer-transaction">
                    <TransactionClass />
                </Route>
                <Route component={NotFound} />
            </Switch>
            <ShowSnackBar/>
            <NotificationContainer/>
        </Router>
    )
    function ShowSnackBar(type='success',message="no message"){
        var success='#0FB172'
        var danger='#DE1D22'
        var info='#2C97EA'
        var color=success
        if(context.notification.type=='danger'){
            color=danger
        }else if(context.notification.type=='info'){
            color=info
        }else if(context.notification.type=='success'){

        }else if(context.notification.type=='warning'){
            
        }
        useEffect(() => {
            if(context.notification.show){
                // openSnackbar(context.notification.message)
                if(context.notification.type=='danger'){
                    NotificationManager.error(context.notification.message);
                }else if(context.notification.type=='info'){
                    NotificationManager.info(context.notification.message);
                }else if(context.notification.type=='success'){
                    NotificationManager.success(context.notification.message);
                }else if(context.notification.type=='warning'){
                    NotificationManager.warning(context.notification.message);
                }
                context.setValue('notification',{show:false,type:'',message:''})
            }
            
        },[context.notification]);
        return null
    }
}