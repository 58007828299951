import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ContextProvider } from "../../context/BaseContext";
import DataTable from "../../component/DataTable";
import { LoadingBall } from "../../component/Loading";
import { currencyFormat, renderStatusPrivat } from "../../util/Functions";
import moment from "moment";

export default function PrivateKelas({ path }) {
  const context = useContext(ContextProvider);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [listDataPrivate, setListDataPrivate] = useState(false);

  async function getPrivateKelas() {
    setLoading(true);
    try {
      let response = await context.getRequest(`getListPrivateRequestMentor`);
      console.log("RESPONSE : ", response);
      if (response) {
        // console.log(response.data.data);
        setListDataPrivate(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("ERROR : ", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPrivateKelas();
  }, []);
  return (
    <>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="page-header-title">
                <h5 className="m-b-10">Riwayat Privat Kelas</h5>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">
                    <i className="feather icon-home" />
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a onClick={(e) => e.preventDefault()} href="#!">
                    Riwayat Privat Kelas
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="user-profile-list">
                <div className="dt-responsive table-responsive">
                  {loading ? (
                    <LoadingBall />
                  ) : (
                    <DataTable
                      className={"table dataTable-style nowrap"}
                      data={listDataPrivate}
                      totalRows={listDataPrivate.length}
                      show={[10, 20, 30, 40, 50]}
                      onChangeSearch={(val) => {
                        console.log(val);
                      }}
                      onChangeLimit={(val) => {
                        console.log(val);
                      }}
                      onChangePage={(val) => {
                        console.log(val);
                      }}
                      column={[
                        // "No",
                        "Kode",
                        "Kelas",
                        "Tanggal",
                        "Kuantitas",
                        "Tipe",
                        "Status",
                        "Harga",
                        "Action",
                      ]}
                      renderItem={(item, i) => (
                        <RenderItem val={item} key={i} />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  function RenderItem({ val }) {
    let hideAction = false;
    let menuunggu = false;

    switch (val.status) {
      case "telah dibayar":
        hideAction = true;
        menuunggu = true;
        break;
      case "Dibatalkan user":
        hideAction = true;
        break;
      case "Dibatalkan mentor":
        hideAction = true;
        break;

      default:
        break;
    }
    return (
      <tr>
        <td>{val.id}</td>
        <td>{val.title}</td>
        <td>
          {val.date_start == val.date_end ? (
            moment(val.date_start).format("DD MMM YYYY")
          ) : (
            <>
              <p className="m-0">
                {moment(val.date_start).format("DD MMM YYYY")} s/d
              </p>
              <p className="m-0">
                {moment(val.date_end).format("DD MMM YYYY")}
              </p>
            </>
          )}
        </td>
        <td>{val.qty_user}</td>
        <td>{val.type.toString() == "1" ? "Online" : "Offline"}</td>
        <td>
          {val.status == 1 &&
          val.payment?.transaction_status == "settlement" ? (
            <span
              style={{ color: "#0f5132", backgroundColor: "#d1e7dd" }}
              className="badge badge-pill badge-light"
            >
              Pembayaran berhasil
            </span>
          ) : (
            renderStatusPrivat(val.status)
          )}
        </td>
        <td>{val.price ? "Rp " + currencyFormat(val.price) : "-"}</td>
        <td>
          <i className="fas fa-ellipsis-h" />

          <div className="overlay-edit">
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `${path}/discuss/${val.id}`,
                  state: val,
                });
              }}
              className="btn btn-icon btn-warning"
            >
              <i className="fas fa-comments"></i>
            </a>
          </div>
        </td>
      </tr>
    );
  }
}
