import React, { useContext, useState, useEffect } from "react";
import { ContextProvider } from "../context/BaseContext";
import { getStorage } from "../util/Functions";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
export default function App(props) {
  const history = useHistory();
  const context = useContext(ContextProvider);
  const [profile, setProfile] = useState(null);
  async function getProfile() {
    let pro = await getStorage("profile_mentor");
    if (pro) {
      let p = JSON.parse(pro);
      setProfile(p);
      // console.log(p)
    } else {
      history.replace("/auth");
    }
  }
  // useEffect(async ()=>{
  //   await context.getCountNotif()
  // },[context.discussCountNotif])
  useEffect(() => {
    getProfile();
  }, [context.profile]);
  return (
    <>
      <nav className="pcoded-navbar theme-horizontal menu-light brand-blue">
        <div className="navbar-wrapper container">
          <div
            className="navbar-content sidenav-horizontal"
            id="layout-sidenav"
          >
            <ul className="nav pcoded-inner-navbar sidenav-inner">
              <li className="nav-item pcoded-menu-caption">
                <label>Navigation</label>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-home" />
                  </span>
                  <span className="pcoded-mtext">Dashboard</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/kelas" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-book" />
                  </span>
                  <span className="pcoded-mtext">Kelas</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/kuis" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-check-square" />
                  </span>
                  <span className="pcoded-mtext">Kuis</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/chat" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-navigation" />
                  </span>
                  <span className="pcoded-mtext">Chat</span>
                  {context.discussCountNotif > 0 ? (
                    <span style={{ color: "#D71C20" }}>
                      {context.discussCountNotif > 99
                        ? "99+"
                        : context.discussCountNotif}
                    </span>
                  ) : null}
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="/notifikasi" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-bell" />
                  </span>
                  <span className="pcoded-mtext">Notifikasi</span>
                </a>
              </li> */}
              <li className="nav-item">
                <a href="/withdraw" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-credit-card" />
                  </span>
                  <span className="pcoded-mtext">Witdraw</span>
                </a>
              </li>
              {profile != null && profile.instansi == 1 ? (
                <li className="nav-item">
                  <a href="/instruktur" className="nav-link ">
                    <span className="pcoded-micon">
                      <i className="feather icon-users" />
                    </span>
                    <span className="pcoded-mtext">Instruktur</span>
                  </a>
                </li>
              ) : null}
              <li className="nav-item">
                <a href="/live-schedule" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-video" />
                  </span>
                  <span className="pcoded-mtext">Jadwal Live Kelas</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/report-private-class" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="feather icon-clipboard" />
                  </span>
                  <span className="pcoded-mtext">Riwayat Kelas Privat</span>
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="/faq" className="nav-link ">
                  <span className="pcoded-micon">
                    <i className="fas fa-question-circle" />
                  </span>
                  <span className="pcoded-mtext">FAQ</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      <header className="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
        <div className="container">
          <div className="m-header">
            <a className="mobile-menu" id="mobile-collapse" href="#!">
              <span />
            </a>
            <a href="#!" className="b-brand">
              {/* ========   change your logo hear   ============ */}
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo_putih2.png`}
                style={{ width: 150, height: 50 }}
                alt=""
                className="logo"
              />
            </a>
            <a
              href="#!"
              className="mob-toggler"
              onClick={(e) => e.preventDefault()}
            >
              <i className="feather icon-more-vertical" />
            </a>
          </div>
          <div className="collapse navbar-collapse">
            {/* <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a href="#!" className="pop-search"><i className="feather icon-search" /></a>
                <div className="search-bar">
                  <input type="text" className="form-control border-0 shadow-none" placeholder="Search hear" />
                  <button type="button" className="close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </li>
            </ul> */}
            <ul className="navbar-nav ml-auto">
              <li>
                <div className="dropdown drp-user">
                  <a
                    onClick={(e) => e.preventDefault()}
                    href="#!"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="feather icon-user" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right profile-notification">
                    <div className="pro-head">
                      {profile ? (
                        <>
                          <img
                            src={profile.avatar}
                            style={{ width: 30, height: 30 }}
                            className="img-radius"
                            alt="UserProfileImage"
                          />
                          <span>{profile.name}</span>
                        </>
                      ) : null}

                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          swal({
                            title: "Kamu yakin akan keluar dari aplikasi?",
                            text: "",
                            icon: "warning",
                            dangerMode: true,
                            buttons: ["Tidak jadi", "Ya, saya akan keluar!"],
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              localStorage.clear();
                              history.replace("/auth");
                              context.setValue("login", false);
                            }
                          });
                          // localStorage.clear();
                          // history.replace("/auth");
                          // context.setValue("login", false);
                        }}
                        className="dud-logout"
                        title="Logout"
                      >
                        <i className="feather icon-log-out" />
                      </a>
                    </div>
                    <ul className="pro-body">
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/profile`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-user" /> Profile
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/change-password`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-lock" /> Ubah Password
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/laporan-penjualan`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-printer" /> Laporan Penjualan Kelas
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/notifikasi`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="feather icon-bell" /> Notifikasi
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/faq`);
                          }}
                          className="dropdown-item"
                        >
                          <i className="fas fa-question-circle" /> FAQ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div className="pcoded-main-container">
        {!context.supportFirebase ? (
          <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Browser ini belum support!</strong> Beberapa fitur tidak
            bisa berjalan dibrowser ini
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ) : null}

        <div className="pcoded-wrapper container">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
